
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonNote,
  IonReorder,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { checkmark, trashOutline } from "ionicons/icons";

import config from "@/config/config";
import store from "@/store";

export default {
  name: "Tool",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue", "deleteTool"],
  components: {
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
    IonInput,
    IonReorder,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonNote,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      tool: props.modelValue,
    });

    const toggleReturned = () => {
      state.tool.returned = !state.tool.returned;
      emit("update:modelValue", state.tool);
    };

    return {
      state,
      store,
      toggleReturned,
      trashOutline,
      checkmark,
      config,
    };
  },
};
