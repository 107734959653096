
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import { reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import {
  calendarNumberOutline,
  calendarOutline,
  constructOutline,
  documentTextOutline,
  hammerOutline,
  imagesOutline,
} from "ionicons/icons";

import VisitMain from "@/components/forms/VisitMain.vue";
import Images from "@/components/lists/Images.vue";
import Tasks from "@/components/lists/Tasks.vue";
import Tools from "@/components/lists/Tools.vue";
import { newImage, SectionsType } from "@/types/auxiliary";

import AddButton from "../buttons/AddButton.vue";

export default {
  name: "VisitView",
  props: {
    modelValue: Object,
    hideJob: Boolean,
    hideEmployeeSelect: Boolean,
    hideCustomerSelect: Boolean,
    separateByDefault: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props: any) {
    const sections = ref<SectionsType>([
      {
        // Includes date, employee, customer, notes, & messaging
        name: "Main",
        icon: documentTextOutline,
        id: "main",
      },
      {
        name: "Tasks",
        icon: constructOutline,
        id: "tasks",
      },
      {
        name: "Tools",
        icon: hammerOutline,
        id: "tools",
      },
      {
        name: "Images",
        icon: imagesOutline,
        id: "images",
      },
    ]);

    const state = reactive({
      visit: props.modelValue,
      enableSave: true,
    });

    const addImage = () => {
      state.visit.data.images.unshift(newImage());
    };

    const selectedSection = ref<string>(sections.value[0].id);

    // AUTO-SAVING FUNCTIONALITY
    watch(state.visit.data, () => state.visit.save());

    return {
      sections,
      state,
      selectedSection,
      addImage,
      icons: {
        calendarOutline,
        calendarNumberOutline,
        hammerOutline,
        constructOutline,
        imagesOutline,
      },
    };
  },
  components: {
    VisitMain,
    Tasks,
    Tools,
    Images,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonCard,
    IonIcon,
    IonLabel,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    AddButton,
  },
};
