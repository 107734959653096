
import { reactive } from "@vue/reactivity";

export default {
  name: "Timer",
  props: {
    startTime: String,
  },
  setup(props: any) {
    const startTime = new Date(props.startTime);
    const timerValue = reactive<any>({});

    const refreshTimer = () => {
      const t = Date.now() - startTime.getTime();
      timerValue.seconds = Math.floor((t / 1000) % 60);
      timerValue.minutes = Math.floor((t / 1000 / 60) % 60);
      timerValue.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      timerValue.days = Math.floor(t / (1000 * 60 * 60 * 24));
    };
    refreshTimer();

    setInterval(refreshTimer, 1000);

    return { timerValue };
  },
  components: {},
};
