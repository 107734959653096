
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonText,
} from "@ionic/vue";
import { computed, reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import {
  playOutline,
  stopOutline,
  timeOutline,
  timerOutline,
} from "ionicons/icons";

import { formatTime } from "@/helpers";

import TimePicker from "./inputs/TimePicker.vue";
import Timer from "./Timer.vue";

export default {
  name: "Timelog",
  props: {
    modelValue: Object,
    title: String,
    hoursPlaceholder: String,
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      time: props.modelValue,
    });

    const startToEndHours = (): number => {
      const startDate = new Date(state.time.start);
      const startHours = startDate.getHours() + startDate.getMinutes() / 60;

      const endDate = new Date(state.time.end);
      const endHours = endDate.getHours() + endDate.getMinutes() / 60;

      const hours = endHours - startHours < 0 ? 0 : endHours - startHours;

      if (hours) return Math.round(hours * 10) / 10;
      return 0;
    };

    const refreshHours = () => {
      state.time.hours = startToEndHours();
    };

    const setStartTime = () => {
      state.time.start = new Date().toISOString();
      console.log(state.time.start);
      if (state.time.start && state.time.end) refreshHours();
    };

    const setEndTime = () => {
      state.time.end = new Date().toISOString();
      if (state.time.start && state.time.end) refreshHours();
    };

    const updateHours = (hoursInput: string) => {
      if (hoursInput) state.time.hours = parseFloat(hoursInput);
    };

    watch(state.time, (newTime) => emit("update:modelValue", newTime));

    // Create a ticking clock animation if only start time exists
    const isTicking = computed(() => {
      const afterStart = new Date() > new Date(state.time.start);
      return state.time.start && !state.time.end && afterStart;
    });

    // Do the start and end times match the hours?
    const mismatch = computed(
      () =>
        state.time.start &&
        state.time.end &&
        state.time.hours != startToEndHours()
    );

    const startDate = ref();

    const confirmStart = () => {
      if (startDate.value == undefined) return;
      startDate.value.$el.confirm(true);
    };

    const endDate = ref();

    const confirmEnd = () => {
      if (endDate.value == undefined) return;
      endDate.value.$el.confirm(true);
    };

    return {
      state,
      icons: {
        playOutline,
        stopOutline,
        timerOutline,
        timeOutline,
      },
      startDate,
      endDate,
      confirmStart,
      confirmEnd,
      mismatch,
      isTicking,
      setStartTime,
      setEndTime,
      refreshHours,
      updateHours,
      formatTime,
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonItem,
    IonText,
    IonNote,
    Timer,
    TimePicker,
  },
};
