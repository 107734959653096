
import {
  IonButton,
  IonButtons,
  IonDatetime,
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
  IonText,
} from "@ionic/vue";
import { reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { timeOutline } from "ionicons/icons";

import { formatTime, generateUUID } from "@/helpers";

export default {
  name: "Time Picker",
  components: {
    IonItem,
    IonPopover,
    IonDatetime,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    IonText,
  },
  props: {
    modelValue: String,
    title: String,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      time: props.modelValue,
    });
    const id = generateUUID();

    watch(state, (newState) => {
      emit("update:modelValue", newState.time);
    });

    const timeRef = ref();

    const confirm = () => {
      if (timeRef.value == undefined) return;
      timeRef.value.$el.confirm(true);
    };

    return {
      state,
      id,
      confirm,
      timeRef,
      formatTime,
      icons: { timeOutline },
    };
  },
};
