
import {
  IonButtons,
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonTextarea,
  IonToolbar,
} from "@ionic/vue";
import {
  calendarNumberOutline,
  calendarOutline,
  documentTextOutline,
  optionsOutline,
  timeOutline,
  timerOutline,
  trashOutline,
} from "ionicons/icons";
import { computed, reactive, ref } from "vue";

import JobsModal from "@/components/modals/JobsModal.vue";
import UserSelect from "@/components/selects/UserSelect.vue";
import TimeLogComponent from "@/components/TimeLog.vue";
import config from "@/config/config";
import {
  formatDate,
  formatTime,
  retrieveCustomerAddress,
  showTextAreas,
} from "@/helpers";
import { companiesCollection, db } from "@/main";
import store from "@/store";
import { JobInterface, Visit } from "@/types/units";

import Address from "../Address.vue";
import DatePicker from "../inputs/DatePicker.vue";
import TimePicker from "../inputs/TimePicker.vue";

interface State {
  visit: Visit;
  jobData: JobInterface | null;
  showTextAreas: boolean;
}

export default {
  name: "Visit Main",
  props: {
    modelValue: Object,
    hideEmployeeSelect: Boolean,
    hideCustomerSelect: Boolean,
    hideJob: Boolean,
  },
  emits: ["update:modelValue"],
  components: {
    IonLabel,
    IonItem,
    IonChip,
    IonTextarea,
    IonToolbar,
    IonNote,
    IonIcon,
    UserSelect,
    TimeLogComponent,
    IonButtons,
    JobsModal,
    IonModal,
    IonInput,
    IonList,
    Address,
    DatePicker,
    TimePicker,
  },
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive<State>({
      visit: props.modelValue,
      jobData: null,
      showTextAreas: false,
    });

    if (store.state.user) {
      console.log(store.state.user.employees);
    }

    const jobsRef = computed(() => {
      if (state.visit.data.customerID) {
        return companiesCollection
          .doc(
            `${store.state.companyID}/customers/${state.visit.data.customerID}`
          )
          .collection("jobs");
      }
      return db
        .collectionGroup("jobs")
        .where("data.companyID", "==", store.state.companyID);
    });

    showTextAreas(state);

    const changeDate = (ev: CustomEvent) => {
      state.visit.data.date = formatDate(ev.detail.value);
      emit("update:modelValue", state.visit);
    };

    const jobsModalIsOpen = ref(false);

    const attachJob = (jobData: JobInterface) => {
      jobsModalIsOpen.value = false;
      state.jobData = jobData;
      state.visit.data.jobID = jobData.id;
      state.visit.data.customerID = jobData.customerID;
      emit("update:modelValue", state.visit);
    };

    const clearJob = () => {
      state.visit.data.jobID = "";
      state.jobData = null;
    };

    const updateCustomer = async (customerID: string) => {
      clearJob();

      // Try to retrieve the address of the customer
      const customerAddress = await retrieveCustomerAddress(customerID);
      if (customerAddress) state.visit.data.location = customerAddress;
      emit("update:modelValue", state.visit);
    };

    const initialize = async () => {
      if (state.visit.data.jobID) {
        const docs = (
          await db
            .collectionGroup("jobs")
            .where("data.id", "==", state.visit.data.jobID)
            .get()
        ).docs;
        if (docs) {
          state.jobData = docs[0].data().data as JobInterface;
        }
      }
    };

    initialize();

    return {
      state,
      store,
      jobsRef,
      updateCustomer,
      clearJob,
      icons: {
        calendarNumberOutline,
        calendarOutline,
        trashOutline,
        timeOutline,
        timerOutline,
        optionsOutline,
        documentTextOutline,
      },
      changeDate,
      jobsModalIsOpen,
      attachJob,
      formatTime,
      config,
    };
  },
};
