
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonReorder,
  IonTextarea,
  IonToolbar,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { cameraOutline, close, trashOutline } from "ionicons/icons";

import config from "@/config/config";
import { getImageURL, showTextAreas } from "@/helpers";
import store from "@/store";

export default {
  name: "Task",
  props: {
    modelValue: Object,
  },
  components: {
    IonItem,
    IonTextarea,
    IonCheckbox,
    IonButtons,
    IonButton,
    IonIcon,
    IonReorder,
    IonInput,
    IonModal,
    IonCard,
    IonToolbar,
  },
  emits: ["update:modelValue", "deleteTask"],
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      task: props.modelValue,
      showTextAreas: false,
      imageModalIsOpen: false,
      imageURL: "",
    });

    showTextAreas(state);

    const changeImage = async (imageRef: string) => {
      state.task.image.ref = imageRef;
      emit("update:modelValue", state.task);
      state.imageURL = await getImageURL(imageRef);
    };

    if (state.task.image.ref)
      getImageURL(state.task.image.ref).then((url) => (state.imageURL = url));

    return {
      state,
      store,
      changeImage,
      config,
      icons: { trashOutline, cameraOutline, close },
    };
  },
};
